"use strict";

var login = function () {

    var LoginModule = {
        settings: {
            contentBlock: '#content-block',
            contactRegisterFormId: '#register-form',
        }
    }

    var initCommonActions = function () {

    }

    var handleSignInFormSubmit = function () {

        $('#tc_login_signin_submit').click(function (e) {
            e.preventDefault();
            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                errorClass: "text-danger is-invalid",
                errorElement: "span",
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true
                    }
                },
                messages: {
                    email: {
                        required: Lang.get('js-validator-mess.required'),
                        email: Lang.get('js-validator-mess.email')
                    },
                    password: {
                        required: Lang.get('js-validator-mess.required')
                    }
                },
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);
            initAppPage.blockUIStart(LoginModule.settings.contentBlock);
            form.submit();
        });
    }

    var handleSignUpFormSubmit = function () {

        $('#tc_login_signup_submit').click(function (e) {
            e.preventDefault();
            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                errorClass: "text-danger is-invalid",
                errorElement: "span",
                rules: {
                    name: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true,
                        remote: {
                            url: '/register/check/email',
                            type: 'post',
                            data: {
                                registration_code: function() {
                                    return $('#email').val();
                                },
                                _token:function() {

                                    return $('#register-form :input[name="_token"]').val();
                                }
                            },
                            error: function (data) {
                                console.log('Error: ', data);
                            }
                        }
                    },
                    registration_code: {
                        required: true,
                        remote: {
                            url: '/register/check/registration_code',
                            type: 'post',
                            data: {
                                registration_code: function() {
                                    return $('#registration_code').val();
                                },
                                _token:function() {

                                    return $('#register-form :input[name="_token"]').val();
                                }
                            },
                            error: function (data) {
                                console.log('Error: ', data);
                            }
                        }
                    },
                    password: {
                        required: true
                    },
                    password_confirmation: {
                        required: true,
                        equalTo: "#password"
                    }
                },
                messages: {
                    name: {
                        required: Lang.get('js-validator-mess.required')
                    },
                    email: {
                        required: Lang.get('js-validator-mess.required'),
                        email: Lang.get('js-validator-mess.email'),
                        remote: Lang.get('js-validator-mess.email_already_exists')
                    },
                    registration_code: {
                        required: Lang.get('js-validator-mess.required'),
                        remote: Lang.get('js-validator-mess.invalid_code')
                    },
                    password: {
                        required: Lang.get('js-validator-mess.required')
                    },
                    password_confirmation: {
                        required: Lang.get('js-validator-mess.required'),
                        equalTo: Lang.get('js-validator-mess.equalTo')
                    }
                },
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);
            initAppPage.blockUIStart(LoginModule.settings.contentBlock);
            form.submit();
        });
    }

    var handleForgotFormSubmit = function () {
        $('#tc_login_forgot_submit').click(function (e) {
            e.preventDefault();
            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                errorClass: "text-danger is-invalid",
                errorElement: "span",
                rules: {
                    email: {
                        required: true,
                        email: true
                    }
                },
                messages: {
                    email: {
                        required: Lang.get('js-validator-mess.required'),
                        email: Lang.get('js-validator-mess.email')
                    }
                },
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);
            initAppPage.blockUIStart(LoginModule.settings.contentBlock);
            form.submit();
        });
    }

    var handleUpdatePasswordFormSubmit = function () {
        $('#tc_login_update_password_submit').click(function (e) {
            e.preventDefault();
            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                errorClass: "text-danger is-invalid",
                errorElement: "span",
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true,
                    },
                    password_confirmation: {
                        required: true,
                        equalTo: "#password"
                    },
                },
                messages: {
                    email: {
                        required: Lang.get('js-validator-mess.required'),
                        email: Lang.get('js-validator-mess.email')
                    },
                    password: {
                        required: Lang.get('js-validator-mess.required')
                    },
                    password_confirmation: {
                        required: Lang.get('js-validator-mess.required'),
                        equalTo: Lang.get('js-validator-mess.equalTo')
                    }
                },
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);
            initAppPage.blockUIStart(LoginModule.settings.contentBlock);
            form.submit();

        });
    }

    return {

        //main function to initiate the module
        init: function () {
            initCommonActions();
            handleSignInFormSubmit();
            handleSignUpFormSubmit();
            handleForgotFormSubmit();
            handleUpdatePasswordFormSubmit();
        }

    };
}();


// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = login;
}

jQuery(document).ready(function () {
    login.init();
});
