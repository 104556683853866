/*
* Here we will load all application plugins
*
* */

try {

    require('jquery-confirm');
    require('jquery-validation');

    window.blockUI = require('jquery-blockui');

} catch (e) {
}
