"use strict";

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output js/scripts.bundle
 */

// Core Scripts
window.initAppPage = require("./views/tc-app-page");

window.login = require("./views/tc-login");
