"use strict";

var initAppPage = function () {

    var actions = function () {

        /*$("#common-modal").draggable({
            handle: ".modal-header"
        });*/

        $('#lang-selector').change(function () {
            var lang = $(this).val();
            $('#lang-form :input[name="lang"]').val(lang);
            console.log( $('.lang-form :input[name="lang"]').val());
            $('#lang-form').submit();
        });

        $('.w-scrool').click(function () {
            console.log($(this).attr('data-scrool').val());
            console.log('ok');
        });

    }

    return {

        //main function to initiate the module
        init: function () {
            actions();

        },

        fnPostForm: function (selector) {
            return $.post({
                url: $(selector).attr('action'),
                data: $(selector).serialize(),
                /*success: function (data) {
                 result = data;

                 },*/
                error: function (data) {
                    console.log('Error: ', data);
                }
            });
        },

        blockUIStart: function (element) {
            $.blockUI({
                target: element,
                textOnly: true,
                css: {
                    border: 'none',
                    padding: '10px 5px 10px 5px',
                    backgroundColor: '#2B3643',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    opacity: .5,
                    color: '#fff',
                    width: '10%',
                    left: '42.5%'
                },
                cenrerY: true,
                message: '<h3 style="font-weight: 100; margin-top: 6px; color: #ffffff;font-size: 18px;"> <img width="25px" src="/images/ajax-loader.gif" />  ' + Lang.get('common.processing') + '...</h3>'
            });
        },

        blockUIStop: function (element) {
            $.unblockUI(element);
        },

        disableElement: function (el, event) {
            event.preventDefault();
            el.prop('disabled', true);
        },

        reloadScript: function (url) {
            $.getScript(url, function (data, textStatus, jqxhr) {
                if (status == "error") {
                    var msg = "Sorry but there was an error: ";
                    console.log(msg + xhr.status + " " + xhr.statusText);
                }
            });

        },

        numberFormat: function (number, decimals) {

            function nbrFormat(number, decimals) {
                if(Lang.getLocale().toUpperCase() == 'FR'){
                    return $.number(number, decimals, '.', ' ');
                }else if(Lang.getLocale().toUpperCase() == 'EN'){
                    return $.number(number, decimals, '.', ',');
                }else{
                    return $.number(number, decimals, '.', ' ');
                }
            }

            if (typeof decimals === "undefined" || decimals === null) {
                decimals = 0;
            }

            if (!$.number) {
                $.getScript('/global/plugins/jquery-number-master/jquery.number.min.js', function (data, textStatus, jqxhr) {
                    if (status == "error") {
                        var msg = "Sorry but there was an error: ";
                        console.log(msg + xhr.status + " " + xhr.statusText);
                    }
                    return nbrFormat(number, decimals);
                });
            }else{
                return nbrFormat(number, decimals);
            }
        },

        inputNumberFormat: function (el, decimals) {

            function nbrFormat(el, decimals) {
                if(Lang.getLocale().toUpperCase() == 'FR'){
                    el.number(true, decimals, '.', ' ');
                }else if(Lang.getLocale().toUpperCase() == 'EN'){
                    el.number(true, decimals, '.', ',');
                }else{
                    el.number(true, decimals, '.', ' ');
                }
            }

            if (typeof decimals === "undefined" || decimals === null) {
                decimals = 0;
            }

            if (!$.number) {
                $.getScript('/global/plugins/jquery-number-master/jquery.number.min.js', function (data, textStatus, jqxhr) {
                    if (status == "error") {
                        var msg = "Sorry but there was an error: ";
                        console.log(msg + xhr.status + " " + xhr.statusText);
                    }
                    return nbrFormat(el, decimals);
                });
            }else{
                return nbrFormat(el, decimals);
            }
        },

        sortContentAsc: function(a, b){
            return ($(b).data('sort')) < ($(a).data('sort')) ? 1 : -1;
        },

        sortContentDesc: function(a, b){
            return ($(b).data('sort')) > ($(a).data('sort')) ? 1 : -1;
        }

    };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = initAppPage;
}

jQuery(document).ready(function () {
    initAppPage.init();
});
